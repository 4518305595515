.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
  border-radius: 50% !important;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: transparent !important;
  color: $battleship-grey;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 10px);
    height: 20px;
    left: -3px;
    top: calc(50% - 10px);
    background-color: $yellowish-orange-light !important;
    z-index: -1;
  }

  &:last-child::after {
    width: 100%;
    left: -2px;
    border-radius: 0 4px 4px 0;
  }

  &:first-child::after {
    width: 100%;
    left: 2px;
    border-radius: 4px 0 0 4px;
  }
}

.DayPicker-Day--today {
  font-weight: 300 !important;
}

.DayPicker-Day.DayPicker-Day--outside {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  color: #d8d8d8 !important;

  &::after {
    display: none !important;
  }
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
  font-size: 14px;
  color: $battleship-grey;
  font-family: 'Open Sans', sans-serif;
}

.DayPicker-Weekday {
  font-size: 16px !important;
  font-family: 'Open Sans', sans-serif !important;
  color: $light-grey-blue !important;
}

.Selectable .DayPicker-Day--start,
.Selectable .DayPicker-Day--end {
  position: relative;
  border: 0;
  border-radius: 50% !important;
  background-color: transparent !important;
  color: $white;
  outline: none;
  z-index: 2;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $yellowish-orange;
    border-radius: 50%;
    z-index: -1;
  }
}

.Selectable .DayPicker-Day--start::after {
  left: 1px;
}

.Selectable .DayPicker-Day--outside {
  background: $white !important;
}

.DayPicker {
  display: block;
  padding: 0 2em;

  @include from(1024px) {
    padding: 0 40px;
  }

  *:focus {
    outline: none;
  }
}

.DayPicker-Day {
  padding: 0.7em !important;
  position: relative;
  z-index: 1;
}

.DayPicker-Caption {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: $battleship-grey;
}
