@mixin space-between-center() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin dashboard-row-basic($padding) {
  border: 1px solid $very-light-blue;
  border-top: 0;
  padding: $padding;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: $pale-grey;
  }
}

@mixin link-mixin() {
  text-decoration: none;
  color: $white;
  transition: 0.05s;

  &:hover {
    color: $yellowish-orange;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

// Media query mixins
@mixin from($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin to($breakpoint) {
  @media (max-width: $breakpoint - 1px) {
    @content;
  }
}

@mixin between($from, $to) {
  @media (min-width: $from) and (max-width: $to - 1px) {
    @content;
  }
}
