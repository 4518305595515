// COLORS
$dark: #252839;
$dusk: #464c6c;
$battleship-grey: #677077;
$light-grey-blue: #b5b5b7;
$very-light-blue: #e5e9ed;
$pale-grey: #f3f7fb;
$white: #fff;
$yellowish-orange: #f0a80a;
$yellowish-orange-light: #ffd476;
$grapefruit: #ff695f;
$aqua-marine: #49c5b6;

// FONTS DESC
$body1-font-size: 14px;
$body1-line-height: 1.5;

// OTHER
$lines-to-show-preview: 2;
