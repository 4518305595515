@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');
@import '../../../node_modules/react-quill/dist/quill.snow.css';
@import '../../../node_modules/react-quill/dist/quill.bubble.css';
@import '../../../node_modules/react-quill/dist/quill.core.css';
@import '../../../node_modules/react-day-picker/lib/style.css';
@import '../../../node_modules/react-day-picker/lib/style.css';
@import '../../../node_modules/@drawbotics/file-icons/dist/style.css';
@import 'utils/mixins';
@import 'utils/variables';
@import 'utils/data-picker';

* {
  box-sizing: border-box;
}

* {
  scrollbar-width: thin !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(181,181,183,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(181,181,183,0.7);
    transition: all 0.4s;
   // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(181,181,183,0.2);
}
*:hover::-webkit-scrollbar-thumb {
  background: rgba(181,181,183,1);
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  background-color: $pale-grey;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.app-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background: $dark;
}

.underline {
  text-decoration: underline !important;
}

.uppercase {
  text-transform: uppercase;
}
.inactive {
  font-style: italic;
  color: $light-grey-blue;
}

.flex {
  display: flex;
}

.color-gray {
  color:  $yellowish-orange;
}

.color-yellow {
  color: $yellowish-orange;
}

.font-bold {
  font-weight: bold;
}

.hover-row-handler {

  .show-on-hover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: 0.1s;
    z-index: 1;
  }

  &:hover {
    background-color: #f3f7fb;

    .show-on-hover {
      opacity: 1;
    }
  }
}

// quill overirdes
.quill {
  .ql-container.ql-snow {
    border: none;
  }
}

@keyframes shift {
  to {
    background-position: 60px 100%;
  }
}

.DayPickerComponent {
  .DayPicker-Month {
    width: 305px;
  }
}
